import service from '@/utils/request';

// 查询评测用户列表
export const getCustomerList = (params) => service.post('/customer/getCustomerList', params);

// 根据ID查询评测用户
export const getCustomerInfo = (params) => service.post('/customer/getCustomerInfo', params);

// 编辑评测用户
export const updateCustomer = (params) => service.post('/customer/updateCustomer', params);

// 新建评测用户
export const createCustomer = (params) => service.post('/customer/createCustomer', params);

// 删除评测用户
export const deleteUser = (params) => service.post('/customer/delete', params);

// 获取性别
export const userSex = (params) => service.get('/dict/getDict/user_sex', { params });

// 获取监护人关系
export const guardianRelation = (params) => service.get('/dict/getDict/guardian_relation', { params });

// 获取孕周
export const gestationalWeeks = (params) => service.get('/dict/getDict/gestational_weeks', { params });

// 获取测评套餐时限
export const evalTimeLimit = (params) => service.get('/dict/getDict/eval_time_limit', { params });

// 获取评测产品
export const getEvalProduct = (params) => service.post('/evalRecord/getEvalProduct', params);

// 创建测评
export const createRecord = (params) => service.post('/evalRecord/createRecord', params);

// 创建测评明细
export const createRecordDetail = (params) => service.post('/evalRecord/createRecordDetail', params);

// 获取评测结果
export const getEvalResult = (params) => service.post('/evalRecord/getEvalResult', params);

// 下载测评报告
export const getReport = (params) => service.post('/evalRecord/getReport', params);

// 首页-电话信息、微信和二位码图片
export const indexPageTel = (params) => service.post('/dictionary/indexPageTel', params);

// 首页-申请专家测评报告
export const applyExpertReport = (params) => service.post('/customer/applyExpertReport', params);

// 测评结果页-专家主任电话信息
export const telPhone = (params) => service.post('/dictionary/telPhone', params);

// 测评页-操作提示视频地址
export const toolRemindVideo = (params) => service.post('/dictionary/toolRemindVideo', params);

// 测评页-儿童发育标准数接口
export const growthStandardData = (params) => service.post('/child/growth/standard', params);

// 测评页-宝宝测评图表数据查询
export const evalRecord = (params) => service.post('/customer/evalRecord', params);

// 测评页-查询测评警示行为
export const waringBehavior = (params) => service.post('/evalRecord/waringBehavior', params);

// 测评页-根据测评记录id查询对应预警
export const queryByEvalRecordId = (params) => service.post('/evalWarning/queryByEvalRecordId', params);

// 测评页-保存测评预警
export const saveEvalWarning = (params) => service.post('/evalWarning/saveEvalWarning', params);

// 根据父级id和名称查询地区
export const queryByParentIdAndName = (params) => service.post('/areaInfo/queryByParentIdAndName', params);

// 首页-查看宝宝测评记录(报告)列表
export const childEvalRecord = (params) => service.post('/customer/childEvalRecord', params);

// 编辑用户-客户手机号和儿童姓名查询客户信息
export const getCustomerByPhoneAndName = (params) => service.post('/customer/getCustomerByPhoneAndName', params);

// 测评页-获取专家评测结果
export const getEvalResultData = (params) => service.post('/evalRecord/getEvalResult', params);

// 测评页-测评报告地址，二维码
export const reportPicAddressTel = (params) => service.post('/dictionary/reportPicAddressTel', params);

// 激活页-获取激活用户
export const getActiveCustomerList = (params) => service.post('/customer/getActiveCustomerList', params);

// 激活页-获取激活用户信息
export const getActiveCustomerInfo = (params) => service.post('/customer/getActiveCustomerInfo', params);

// 测评页-获取产品
export const getOrgEvalProduct = (params) => service.post('/evalRecord/getOrgEvalProduct', params);
