<template>
  <div class="question">
    <Note v-if="processStatus===0" />
    <Warn
      v-else-if="[1.5,2].includes(processStatus)"
      :disabled="processStatus===2"
      @selectWarn="selectWarn"
    />
    <template v-else>
      <template v-if="!((products[productIndex]||{}).productItems||[]).length">
        <div class="no-data">
          无测评数据
        </div>
      </template>
      <template>
        <template v-for="(question, qIndex) in productItems">
          <template v-for="(option, opIndex) in productItemOptionsList">
            <Topic
              v-if="qIndex===questionIndex &&opIndex===selectIndex"
              :key="`${qIndex}_${opIndex}`"
              :product="products[productIndex]"
              :question="question"
              :option="option"
              :other="{ index: opIndex, len: (productItemOptionsList||[]).length}"
              @select="selectHandler"
            />
          </template>
        </template>
      </template>
    </template>

    <div class="tool">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-button
            round
            class="linear-btn next"
            :disabled="disablePrev"
            @click="prev"
          >
            上一题
          </el-button>
        </el-col>
        <el-col :span="8">
          <div class="text-center">
            <el-button
              round
              class="linear-btn next"
              :disabled="processStatus!==2"
              @click="$router.replace({
                name: 'assess.expertReport',
                query: {...$route.query, evalRecordId: evalRecordId}
              })"
            >
              <!-- params: { evalRecordId: evalRecordId }, -->
              查看测评结果
            </el-button>
          </div>
        </el-col>
        <el-col
          :span="8"
          class="text-right"
        >
          <el-button
            v-if="processStatus===0"
            round
            class="linear-btn next"
            @click="next"
          >
            下一步
          </el-button>
          <el-button
            v-else-if="processStatus===1.5"
            round
            class="linear-btn next"
            :disabled="!(typeof warnInfo.value === 'boolean')"
            @click="submitWarn"
          >
            提交测评
          </el-button>
          <el-button
            v-else-if="showNextQuestion"
            round
            class="linear-btn next"
            :loading="isLoading"
            :disabled="processStatus===2"
            @click="nextQuestionHandler"
          >
            {{ finish?'提交测评':'下一个项目测试' }}
          </el-button>
          <el-button
            v-else-if="processStatus!==2"
            round
            class="linear-btn next"
            :disabled="disableNext"
            @click="next"
          >
            下一题
          </el-button>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { getEvalProduct, createRecordDetail, saveEvalWarning } from '@/api';

export default {
  name: 'Question',
  components: {
    Note: () => import('./components/note.vue'),
    Topic: () => import('./components/topic.vue'),
    Warn: () => import('./components/warn..vue'),
  },
  data() {
    return {
      products: [],
      productIndex: 0,
      questionIndex: 0,
      selectIndex: 0,
      processStatus: 0, // 0测评未开始，1测评进行中，2测评结束
      curObj: {},
      productItems: [],
      productItemOptionsList: [],
      selectValue: '',
      isLoading: false,
      warnInfo: {}
    };
  },
  computed: {
    firstSelect() {
      return this.selectIndex === 0;
    },
    firstQuestion() {
      return this.questionIndex === 0;
    },
    firstProduct() {
      return this.productIndex === 0;
    },
    disablePrev() {
      return (this.firstSelect && this.firstQuestion && this.firstProduct) || this.processStatus === 0;
    },
    disableNext() {
      // if (this.processStatus) {
      //   const isLast = this.selectIndex + 1 >= this.productItemOptionsList.length;
      //   return (isLast && !this.curObj.productItemSelect) || this.processStatus === 2;
      // }
      // return false;
      return typeof this.productItemOptionsList[this.selectIndex]?.isSelect !== 'boolean';
    },
    // 显示下一测试题
    showNextQuestion() {
      return this.productItemOptionsList.length === this.selectIndex + 1;
    },
    id() {
      return Number(this.$route.query.id);
    },
    evalRecordId() {
      return Number(this.$route.query.evalRecordId);
    },
    lastSelect() {
      return this.productItemOptionsList.length === this.selectIndex + 1;
    },
    lastQuestion() {
      return this.productItems.length === this.questionIndex + 1;
    },
    lastProduct() {
      return this.products.length === this.productIndex + 1;
    },
    finish() {
      return this.lastSelect && this.lastQuestion && this.lastProduct;
    }
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      this.getEvalProduct();
    },
    // 获取测评产品
    getEvalProduct() {
      const params = {
        id: this.id
      };
      getEvalProduct(params).then((res) => {
        this.products = res.data || [];
        this.defaultSetting();
      }, (err) => {
        this.products = [];
      });
    },
    // 获取页面刷新前数据
    defaultSetting() {
      this.processStatus = +this.getLocal('processStatus') || 0;
      this.selectIndex = +this.getLocal('selectIndex') || 0;
      this.questionIndex = +this.getLocal('questionIndex') || 0;
      this.productIndex = +this.getLocal('productIndex') || 0;
      this.selectValue = this.getLocal('selectValue') || '';
      this.convertData();
    },
    selectWarn(val) {
      this.warnInfo = val;
      // this.$router.replace({
      //   name: 'assess.expertReport',
      //   query: { ...this.$route.query, evalRecordId: this.evalRecordId }
      // });
    },
    submitWarn() {
      if (!this.warnInfo.value) {
        this.setData('processStatus', 2);
        return;
      }
      const params = {
        evalRecordId: this.evalRecordId,
        warningBehavior: this.warnInfo.str
      };
      saveEvalWarning(params).then((res) => {
        this.$message.success(res.msg || '提交预警成功！');
        this.setData('processStatus', 2);
      });
    },
    // 上一步
    prev() {
      if (this.processStatus === 2) {
        this.setData('processStatus', 1);
      }
      if (this.processStatus !== 0) {
        this.step(-1);
      }
      this.convertData();
    },
    next(tag) {
      // 选择是，切换下一题
      // 选择否
      // --不是倒数第二个选项，切换下一个选项
      // --倒数第二题，默认选择最后一个选项，切换下一题
      // --==如果不是最后一题，切换下一题
      // --==如果是最后一题，不是最后一套题，切换下一套
      // --==如果是最后一套题，提交测试，显示测试结果
      const doNext = this.doNextHandler();
      if (!tag && !doNext) return;
      if (this.processStatus === 1) {
        this.step(1, tag);
      }
      this.convertData();
      if (this.processStatus === 0) {
        this.setData('processStatus', 1);
      }
    },
    // 产品，题目，选项索引逻辑处理
    step(step, tag) {
      if (tag === 'nextQuestion') {
        this.stepNextQuestion();
        return;
      }
      const nextIndex = this.selectIndex + step;
      if (nextIndex >= this.productItemOptionsList.length) {
        this.stepNextQuestion();
      } else if (nextIndex < 0) {
        this.stepPrevQuestion();
      } else { // 下一个选项
        this.setData('selectIndex', nextIndex);
      }
    },
    stepPrevQuestion() {
      if (this.questionIndex - 1 < 0) {
        if (this.productIndex - 1 >= 0) {
          this.setData('productIndex', this.productIndex - 1);
          this.setData('questionIndex', 0);
          this.setData('selectIndex', 0);
          this.setData('selectValue', '');
        }
      } else {
        this.setData('questionIndex', this.questionIndex - 1);
        this.setData('selectIndex', 0);
        this.setData('selectValue', '');
      }
    },
    stepNextQuestion() {
      if (this.questionIndex + 1 >= this.productItems.length) {
        if (this.productIndex + 1 >= this.products.length) {
          this.setData('processStatus', 1.5);
        } else { // 切换下一套
          this.setData('productIndex', this.productIndex + 1);
          this.setData('questionIndex', 0);
          this.setData('selectIndex', 0);
          this.setData('selectValue', '');
        }
      } else { // 下一题
        this.setData('questionIndex', this.questionIndex + 1);
        this.setData('selectIndex', 0);
        this.setData('selectValue', '');
      }
    },
    // 当前选项数据
    convertData() {
      if (!(this.products || []).length) return;
      const { productItems } = this.products[this.productIndex];
      if ((productItems || []).length) {
        const { productItemOptionsList } = productItems[this.questionIndex];
        this.productItems = productItems;
        this.productItemOptionsList = productItemOptionsList;
      } else {
        this.curObj = {};
        this.productItems = [];
        this.productItemOptionsList = [];
      }
    },
    // 选择回调
    selectHandler(data, isSelect, last) {
      if (isSelect) { // 选择后切换下一题
        // 选择是后，创建
        // this.createRecordDetail(data, last);
      } else {
      }
    },
    nextQuestionHandler() {
      const data = {
        ...this.products[this.productIndex],
        ...this.productItems[this.questionIndex],
        ...this.productItemOptionsList[this.selectIndex],
      };
      this.createRecordDetail(data);
    },
    doNextHandler() {
      const selectItem = this.productItemOptionsList[this.selectIndex];
      if (selectItem?.isSelect) {
        const question = this.productItems[this.questionIndex];
        this.createRecordDetail({
          ...selectItem,
          productId: question.productId,
          productItemId: question.id,
          productItemName: question.prductItemName
        });
        return false;
      }
      return true;
    },
    // 创建测评明细
    createRecordDetail(item, last) {
      if (this.isLoading) return;
      this.isLoading = true;
      const params = {
        evalRecordId: this.evalRecordId,
        productId: item.productId,
        productItemId: item.productItemId,
        productItemSelect: item.itemOptionsChar,
        productItemName: item.prductItemName
      };
      createRecordDetail(params).then((res) => {
        this.next('nextQuestion');
      }).finally((res) => {
        this.isLoading = false;
      });
    },
    // 私有的测评key
    setKey(key) {
      return `question_${this.id}_${key}`;
    },
    // 设置local
    setLocal(key, value) {
      sessionStorage.setItem(this.setKey(key), value);
    },
    // 获取local
    getLocal(key) {
      return sessionStorage.getItem(this.setKey(key));
    },
    // 设置数据到data和local
    setData(key, value) {
      this.$set(this.$data, key, value);
      this.setLocal(key, value);
    },
    // 移除单个local
    removeLocal(key) {
      sessionStorage.removeItem(this.setKey(key));
    },
    // 移除测评数据
    cleanLocal() {
      Object.keys(sessionStorage).forEach((key) => {
        if (key.padStart('question_')) {
          sessionStorage.removeItem(key,);
        }
      });
    }
  },
  beforeRouteLeave(to, from, next) {
    // 什么都不写的时候，不会离开(走下一步)
    // this.$confirm('确认关闭？')
    //   .then((_) => {
    //     console.log(123);
    //   })
    //   .catch((_) => {});
    this.cleanLocal();
    next();
  }
};
</script>

<style lang="less" scoped>
.question{
  padding-top: 16px;
  margin: 0 auto;
  width: 500px;
  .tool{
    padding-bottom: 16px;
  }
  .no-data{
    height: 200px;
    line-height: 200px;
    text-align: center;
    background: #f8f8f8;
    margin-bottom: 16px;
    color: #999;
  }
}
</style>
